import React, { useState } from "react";
import { Col, Row, Input, Label, Jumbotron, Table } from "reactstrap";

const expectedRound = require("expected-round");

const FeedChart = ({ state }) => {
  const [volState, setVolState] = useState(() => 1);
  const products = [...state.bases, ...state.additives];
  const vegColumns = [];
  const bloomColumns = [];

  // Fills vegColumns with feed rates to iterate into table
  products.map(({ rates, unit }) => {
    const rateColumn = [];
    for (let i = 0; i < 4; i++) {
      if ("veg" in rates && rates.veg[i] !== "--") {
        rateColumn.push(
          expectedRound.round10(rates.veg[i] * volState, -2) + unit
        );
      } else {
        rateColumn.push("--");
      }
    }
    return vegColumns.push(rateColumn);
  });

  // Fills bloomColumns with feed rates to iterate into table
  products.map(({ rates, unit }) => {
    const rateColumn = [];
    for (let i = 0; i < 8; i++) {
      if ("bloom" in rates && rates.bloom[i] !== "--") {
        rateColumn.push(
          expectedRound.round10(rates.bloom[i] * volState, -2) + unit
        );
      } else {
        rateColumn.push("--");
      }
    }
    return bloomColumns.push(rateColumn);
  });

  function handleChange(event) {
    if (event.target.value < 0) {
      setVolState(0);
    } else {
      setVolState(event.target.value);
    }
  }

  return (
    <>
      <Jumbotron id="jumbotron" className="text-light bg-dark text-center">
        <Row className="m-0">
          <Col></Col>
          <Col size="8">
            <small className="text-light">
              <strong className="text-light">Selected: </strong>
              {state.system} -{" "}
              {state.mediaSelection.charAt(0).toUpperCase() +
                state.mediaSelection.slice(1)}
            </small>
            <br className="mb-3" />
          </Col>
          <Col></Col>
        </Row>
        <Row className="m-0">
          <Col></Col>
          <Col size="3" className="text-center">
            <Label>Desired Volume:</Label>
            <br />
            <Label>(Litres)</Label>
          </Col>
          <Col size="5" className="mt-sm-1 mt-3">
            <Input
              id="volume"
              className="text-center"
              value={volState}
              onChange={handleChange}
              placeholder={1}
              type="number"
              min={0}
              max={9999}
            ></Input>
          </Col>
          <Col></Col>
        </Row>
      </Jumbotron>
      <Table
        responsive
        hover
        size="sm"
        id="vegTable"
        striped
        className="mt-3 mb-0 text-center table-dark align-middle"
      >
        <thead key="veg_headers">
          <tr>
            <th>Veg. Week</th>
            {products.map(({ product }) => (
              <th key={"veg_" + product}>{product}</th>
            ))}
          </tr>
        </thead>
        <tbody colSpan={15}>
          {[0, 1, 2, 3].map((index) => {
            return (
              <tr key={"veg_week_" + (index + 1)}>
                <th>{index + 1}</th>
                {vegColumns.map((rates, index2) => (
                  <td key={index2 + "_veg_" + rates[index]}>{rates[index]}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Table
        responsive
        hover
        size="sm"
        id="bloomTable"
        striped
        className="mt-3 mb-0 text-center table-dark align-middle"
      >
        <thead key="bloom_headers">
          <tr>
            <th>Bloom Week</th>
            {products.map(({ product }) => (
              <th key={"bloom_" + product}>{product}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {[0, 1, 2, 3, 4, 5, 6, 7].map((index) => {
            return (
              <tr key={"bloom_week_" + (index + 1)}>
                <th>{index + 1}</th>
                {bloomColumns.map((rates, index2) => (
                  <td key={index2 + "_bloom_" + rates[index]}>
                    {rates[index]}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </Table>
      <br />
    </>
  );
};

export default FeedChart;