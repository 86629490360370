// Note: These objects are also instantiated in the setState functions, changes should be reflected there as well.
export const mediaType = [
  { media: "Soil & Coco", mediaCode: "soil", bg: "soil" },
  { media: "Hydro", mediaCode: "hydro", bg: "blue" },
];

export const bases = [
  {
    system: "Ultimate 2 Part",
    bases: [
      {
        product: "Grow A",
        rates: {
          veg: [0.6, 2.0, 3.0, 3.5],
        },
        unit: "ml",
      },
      {
        product: "Grow B",
        rates: {
          veg: [0.6, 2.0, 3.0, 3.5],
        },
        unit: "ml",
      },
      {
        product: "Bloom A",
        rates: {
          bloom: [2.0, 3.0, 3.5, 4.5, 3.5, 3.5, 3.0, "--"],
        },
        unit: "ml",
      },
      {
        product: "Bloom B",
        rates: {
          bloom: [2.0, 3.0, 3.5, 4.5, 3.5, 3.5, 3.0, "--"],
        },
        unit: "ml",
      },
    ],
  },
  {
    system: "H.O.G. 3 Part",
    bases: [
      {
        product: "HOG Grow",
        rates: {
          veg: [0.3, 1, 3.5, 3.5],
          bloom: [2, 1, 1, 1, 1, 1, 1, 0],
        },
        unit: "ml",
      },
      {
        product: "HOG Micro",
        rates: {
          veg: [0.3, 1, 2, 2],
          bloom: [2, 2, 2, 2, 2, 2, 2, 0],
        },
        unit: "ml",
      },
      {
        product: "HOG Bloom",
        rates: {
          veg: [0.3, 1, 1, 1],
          bloom: [2, 3.5, 3.5, 3.5, 3.5, 3.5, 3.5, 0],
        },
        unit: "ml",
      },
    ],
  },
];

export const additives = [
  {
    product: "Ultimate B+",
    rates: {
      veg: [0.5, 0.5, 0.5, 0.5],
      bloom: [0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, "--"],
    },
    unit: "ml",
  },
  {
    product: "Red Sun",
    rates: {
      bloom: [0.5, "--", "--", "--", "--", "--", "--", "--"],
    },
    unit: "g",
  },
  {
    product: "Bud Fusion",
    rates: {
      bloom: ["--", 0.2, 0.3, 0.3, 0.2, "--", "--", "--"],
    },
    unit: "g",
  },
  {
    product: "Sea Storm",
    rates: {
      veg: [5, 5, 5, 5],
      bloom: [5, 5, 5, 5, 5, 5, 5, "--"],
    },
    unit: "ml",
  },
  {
    product: "Black Storm",
    rates: {
      soil: {
        rates: {
          veg: [0.1, 0.1, 3, 3],
          bloom: [3, 3, 3, 3, 3, 3, 3, "--"],
        },
        unit: "ml",
      },
      hydro: {
        rates: {
          veg: [0.1, 0.1, 0.5, 0.5],
          bloom: [0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, "--"],
        },
      },
      unit: "ml",
    },
  },
  {
    product: "Gold Storm",
    rates: {
      veg: [3.0, 7.0, 7.0, 7.0],
    },
    unit: "ml",
  },
  {
    product: "HOG Carbs",
    rates: {
      bloom: ["--", 1, 1, 1, 1, 1, 1, "--"],
    },
    unit: "ml",
  },
  {
    product: "Jet Fuel",
    rates: {
      bloom: ["--", "--", "--", "--", "--", 2, 2, "--"],
    },
    unit: "ml",
  },
  {
    product: "Typhoon Cleanse",
    rates: {
      bloom: ["--", "--", "--", "--", "--", "--", "--", 2.5],
    },
    unit: "ml",
  },
];
