import React, { useState } from "react";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { Badge, Button, ButtonGroup, Container, Row, Col, Card } from "reactstrap";
import NavBar from "./components/NavBar.js";
import FeedChart from "./components/FeedChart.js";
import { mediaType, bases, additives } from "./components/Data.js";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

// ReactGA.initialize('UA-80368528-1');
// ReactGA.pageview(window.location.pathname + window.location.search);

const history = createBrowserHistory()

const App = () => {
  // To-do cleanup/remove media/baseSelection states and just use main state hook.
  const [mediaSelection] = useState(() => "soil");
  const [baseSelection] = useState(() => ({
     ...bases[0],
      additives: additives.map((object) => 
          object["product"] !== "Black Storm" ? object : {product: "Black Storm", rates: object["rates"][mediaSelection]["rates"], unit: "ml"}
      ),
  }));
  const [state, setState] = useState(() => ({...baseSelection, mediaSelection}));

  return (
    <Router history={history}>
      <div className="App full-height">
        <NavBar />
        <Container>
          <Title title="Home of the IPP Nutrient Calculator" />
          <Row id="base-select" className="mx-5 mb-2">
            <Col className="p-0 mx-xl-5 mx-1">
              <Card className="mb-1 bg-transparent border-0">
                <ButtonGroup>
                  {bases.map(({ system, bases }) => {
                    return (
                      <BaseSelector
                        key={system}
                        baseSelection={system}
                        setBaseSelection={() => {
                          setState({
                            system: system,
                            bases: bases,
                            additives: additives.map((object) => {
                              return object["product"] !== "Black Storm"
                                ? object
                                : {
                                    product: "Black Storm",
                                    rates:
                                      object["rates"][state.mediaSelection][
                                        "rates"
                                      ],
                                    unit: "ml",
                                  };
                            }),
                            mediaSelection: state.mediaSelection,
                          });
                        }}
                      />
                    );
                  })}
                </ButtonGroup>
              </Card>
              <Row id="media-select" className="text-center m-0">
                {mediaType.map(({ media, mediaCode, bg }) => {
                  return (
                    <MediaSelector
                      key={mediaCode}
                      bg={bg}
                      mediaSelection={media}
                      setMediaSelection={() => {
                        setState({
                          system: state.system,
                          bases: state.bases,
                          additives: additives.map((object) => {
                            return object["product"] !== "Black Storm"
                              ? object
                              : {
                                  product: "Black Storm",
                                  rates: object["rates"][mediaCode]["rates"],
                                  unit: "ml",
                                };
                          }),
                          mediaSelection: mediaCode,
                        });
                      }}
                    />
                  );
                })}
              </Row>
            </Col>
          </Row>
        </Container>
        <FeedChart state={state} setState={setState} className="FeedChart"/>
      </div>
    </Router>
  );
};

const Title = ({ title }) => {
  return (
    <Row className="m-0 mb-1">
      <Col>
        <h1 className="text-center text-danger">{title}</h1>
      </Col>
    </Row>
  );
};

const BaseSelector = ({ baseSelection, setBaseSelection }) => {
  return (
        <Button block className="w-100" color="danger" onClick={setBaseSelection}>
          {baseSelection}
        </Button>
  );
};

const MediaSelector = ({mediaSelection, setMediaSelection, bg}) => {
  return (
    <Col xs={6} className="px-2">
      <Badge
        as="h2"
        type="button"
        className="w-100"
        color={bg}
        onClick={setMediaSelection}
      >
        {mediaSelection}
      </Badge>
    </Col>
  );
}

export default App;
