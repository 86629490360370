import React, { useState } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import logo from '../images/logo.png'

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div id="NavBar">
      <Navbar dark expand="sm" className="mx-3 mx-md-5">
        <NavbarBrand href="https://innovatingplantproducts.com/">
          <img
            src={logo}
            alt="Innovating Plant Products Logo"
            className="nav-logo"
          />
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="mr-auto" navbar>
            <NavItem>
              <NavLink href="https://innovatingplantproducts.com/">
                Return to Site
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="https://innovatingplantproducts.com/category/products/">
                Products
              </NavLink>
            </NavItem>
            <UncontrolledDropdown id="retailers-dropdown" nav inNavbar>
              <DropdownToggle nav caret>
                Retailers
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem href="https://innovatingplantproducts.com/store-locator/">
                  Find A Retailer
                </DropdownItem>
                <DropdownItem href="https://innovatingplantproducts.com/login/">
                  Retailer Sign-Up
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <UncontrolledDropdown id="faq-dropdown" nav inNavbar>
              <DropdownToggle nav caret>
                FAQ
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem href="https://innovatingplantproducts.com/faqs/">
                  General FAQ
                </DropdownItem>
                <DropdownItem href="https://innovatingplantproducts.com/feed-charts/">
                  Feed Charts
                </DropdownItem>
                <DropdownItem href="https://innovatingplantproducts.com/ph-cannabis/">
                  Cannabis &amp; pH
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <NavItem>
              <NavLink href="https://innovatingplantproducts.com/contact">
                Contact
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
};

export default NavBar;
